import { useEffect, useState, useRef, useContext } from 'react';
import createStore from 'ctx-provider';
import { cloneDeep, isEqual } from 'lodash';
import authenticationCtx from 'context/authentication';
import useAlert from 'hooks/useAlert';
import { getTradesHistory, getClosedPositions, getOpenOrders, getPositions } from 'api/trading-api';
import { zoneDate } from 'lib/zone-date';

const REFRESH_TIME_MS = 5 * 1000;

const VALID_TRADETYPES = {
	1: true, // limit or market order
	6: true, // liquidation
};

const useTrades = () => {
	// const { exchange } = useContext(exchangeCtx);
	const alert = useAlert();
	const { isLoggedIn } = useContext(authenticationCtx);

	const [subscriptionTradesHistory, setSubscriptionTradesHistory] = useState(true);
	const [subscriptionOpenOrders, setSubscriptionOpenOrders] = useState(false);
	const [subscriptionClosedPositions, setSubscriptionClosedPositions] = useState(false);
	const [subscriptionPositions, setSubscriptionPositions] = useState(false);

	const [tradesHistory, setTradesHistory] = useState([]);
	const [openOrders, setOpenOrders] = useState([]);
	const [closedPositions, setClosedPositions] = useState([]);
	const [positions, setPositions] = useState([]);
	const [positionsLoaded, setPositionsLoaded] = useState(false);

	const timeoutTradesHistoryId = useRef();
	const timeoutOpenOrdersId = useRef();
	const timeoutClosedPositionsId = useRef();
	const timeoutPositionsId = useRef();
	const positionsLoadedRef = useRef(false);
	const prevPositionsRef = useRef();
	const prevTradesHistoryRef = useRef();
	const prevOpenOrdersRef = useRef();
	const prevClosedPositionsRef = useRef();
	const firstTimePositionsRequestRef = useRef(true);

	const subscribeTradesHistory = () => {
		prevTradesHistoryRef.current = null;
		setSubscriptionTradesHistory(true);
	};

	const subscribeOpenOrders = () => {
		prevOpenOrdersRef.current = null;
		setSubscriptionOpenOrders(true);
	};

	const subscribeClosedPositions = () => {
		prevClosedPositionsRef.current = null;
		setSubscriptionClosedPositions(true);
	};

	const subscribePositions = () => {
		prevPositionsRef.current = null;
		setSubscriptionPositions(true);
	};

	const unsubscribeTradesHistory = () => {
		setSubscriptionTradesHistory(false);
	};

	const unsubscribeOpenOrders = () => {
		setSubscriptionOpenOrders(false);
	};

	const unsubscribeClosedPositions = () => {
		setSubscriptionClosedPositions(false);
	};

	const unsubscribePositions = () => {
		setSubscriptionPositions(false);
	};

	const cancelTimeoutTradesHistory = () => {
		if (timeoutTradesHistoryId.current) {
			clearTimeout(timeoutTradesHistoryId.current);
		}
	};

	const cancelTimeoutOpenOrders = () => {
		if (timeoutOpenOrdersId.current) {
			clearTimeout(timeoutOpenOrdersId.current);
		}
	};

	const cancelTimeoutClosedPositions = () => {
		if (timeoutClosedPositionsId.current) {
			clearTimeout(timeoutClosedPositionsId.current);
		}
	};

	const cancelTimeoutPositions = () => {
		if (timeoutPositionsId.current) {
			clearTimeout(timeoutPositionsId.current);
		}
	};

	const loadTradesHistory = async () => {
		if (isLoggedIn) {
			try {
				const exchange = 'phemex';
				const network = 'contract';
				let symbol, limit, offset;
				let data = await getTradesHistory({ exchange, network, symbol, limit, offset });
				if (data) {
					data = data.filter(trade => VALID_TRADETYPES[trade.tradeType]);
					const newData = cloneDeep(data);
					if (!prevTradesHistoryRef.current || !isEqual(newData, prevTradesHistoryRef.current)) {
						prevTradesHistoryRef.current = cloneDeep(data);
						setTradesHistory(newData);
					}
				}
			} catch (err) {
				const message = err.response?.data?.error || err.message || 'Server error';
				// send an alert, but prevent alerting multiple times, by marking this message with "match":
				alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			}
		}
		cancelTimeoutTradesHistory();
		timeoutTradesHistoryId.current = setTimeout(loadTradesHistory, REFRESH_TIME_MS);
	};

	const loadOpenOrders = async () => {
		if (isLoggedIn) {
			try {
				const exchange = 'phemex';
				const network = 'contract';
				let symbol = 'ETH';
				let data = await getOpenOrders({ exchange, network, symbol });
				if (data) {
					const newData = cloneDeep(data);
					if (!prevOpenOrdersRef.current || !isEqual(newData, prevOpenOrdersRef.current)) {
						prevOpenOrdersRef.current = cloneDeep(data);
						setOpenOrders(newData);
						// setOpenOrders(data);
					}
				}
			} catch (err) {
				const message = err.response?.data?.error || err.message || 'Server error';
				// send an alert, but prevent alerting multiple times, by marking this message with "match":
				alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			}
		}
		cancelTimeoutOpenOrders();
		timeoutOpenOrdersId.current = setTimeout(loadOpenOrders, REFRESH_TIME_MS);
	};

	const loadClosedPositions = async () => {
		if (isLoggedIn) {
			try {
				const exchange = 'phemex';
				const network = 'contract';
				let symbol, limit, offset;
				let data = await getClosedPositions({ exchange, network, symbol, limit, offset });
				if (data) {
					const newData = cloneDeep(data);
					if (!prevClosedPositionsRef.current || !isEqual(newData, prevClosedPositionsRef.current)) {
						prevClosedPositionsRef.current = cloneDeep(data);
						setClosedPositions(newData);
					}
				}
			} catch (err) {
				const message = err.response?.data?.error || err.message || 'Server error';
				// send an alert, but prevent alerting multiple times, by marking this message with "match":
				alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			}
		}
		cancelTimeoutClosedPositions();
		timeoutClosedPositionsId.current = setTimeout(loadClosedPositions, REFRESH_TIME_MS);
	};

	const loadPositions = async options => {
		let data;
		const requestfromExchange = !!options?.requestfromExchange;
		const onlyFromDatabase = !requestfromExchange;
		if (isLoggedIn) {
			try {
				const exchange = 'phemex';
				const network = 'contract';
				data = await getPositions({ exchange, network, onlyFromDatabase });

				if (data) {
					data.forEach(position => {
						position.dateOpened = zoneDate(position.transactTimeNs / 1000000);
						position.leverage = parseFloat(position.leverageRr);
						position.ownValue = parseFloat(position.usedBalanceRv);
						position.entryPositionValue = position.leverage * position.ownValue;
						position.entryPrice = parseFloat(position.avgEntryPriceRp);
						position.currentPrice = parseFloat(position.markPriceRp);
						position.currentSize = parseFloat(position.size);
						position.liquidationPrice = parseFloat(position.liquidationPriceRp);
						position.liquidationLevel =
							100 *
							Math.abs(
								(position.entryPrice - position.currentPrice) / (position.entryPrice - position.liquidationPrice),
							);
						position.currentTradeCost = parseFloat(position.curTermRealisedPnlRv);
						position.currentPositionValueWithoutFeeCorrection =
							(position.entryPositionValue * position.currentPrice) / position.entryPrice;
						position.currentPositionValue = position.currentSize * position.currentPrice;
						position.feeCorrection = position.currentPositionValueWithoutFeeCorrection - position.currentPositionValue;

						let profit;
						if (position.isLong) {
							position.profit =
								position.currentPositionValue -
								position.entryPositionValue +
								position.feeCorrection +
								2 * position.currentTradeCost;
						} else {
							position.profit =
								position.entryPositionValue -
								position.currentPositionValue -
								position.feeCorrection +
								2 * position.currentTradeCost;
						}
					});

					const newPositions = cloneDeep(data);
					if (!prevPositionsRef.current || !isEqual(newPositions, prevPositionsRef.current)) {
						prevPositionsRef.current = cloneDeep(data);
						setPositions(newPositions);
					}
				}
				if (!positionsLoadedRef.current) {
					positionsLoadedRef.current = true;
					setPositionsLoaded(true);
				}
			} catch (err) {
				const message = err.response?.data?.error || err.message || 'Server error';
				// send an alert, but prevent alerting multiple times, by marking this message with "match":
				alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			}

			// when first time and empty result, we will ask a list not from cache
			if ((!data || data.length === 0) && !requestfromExchange && firstTimePositionsRequestRef.current) {
				firstTimePositionsRequestRef.current = false;
				loadPositions({ requestfromExchange: true });
			}
		}
		cancelTimeoutPositions();
		timeoutPositionsId.current = setTimeout(loadPositions, REFRESH_TIME_MS);
	};

	useEffect(() => {
		return () => {
			cancelTimeoutTradesHistory();
			cancelTimeoutOpenOrders();
			cancelTimeoutClosedPositions();
			cancelTimeoutPositions();
		};
	}, []);

	useEffect(() => {
		if (isLoggedIn) {
			if (subscriptionTradesHistory) {
				cancelTimeoutTradesHistory();
				loadTradesHistory();
			}
			if (subscriptionOpenOrders) {
				cancelTimeoutOpenOrders();
				loadOpenOrders();
			}
			if (subscriptionClosedPositions) {
				cancelTimeoutClosedPositions();
				loadClosedPositions();
			}
			if (subscriptionPositions) {
				cancelTimeoutPositions();
				loadPositions();
			}
		} else {
			cancelTimeoutTradesHistory();
			cancelTimeoutOpenOrders();
			cancelTimeoutClosedPositions();
			cancelTimeoutPositions();
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (isLoggedIn && subscriptionTradesHistory) {
			loadTradesHistory();
		} else {
			cancelTimeoutTradesHistory();
			setTradesHistory([]);
		}
	}, [isLoggedIn, subscriptionTradesHistory]);

	useEffect(() => {
		if (isLoggedIn && subscriptionOpenOrders) {
			loadOpenOrders();
		} else {
			cancelTimeoutOpenOrders();
			setOpenOrders([]);
		}
	}, [isLoggedIn, subscriptionOpenOrders]);

	useEffect(() => {
		if (isLoggedIn && subscriptionClosedPositions) {
			loadClosedPositions();
		} else {
			cancelTimeoutClosedPositions();
			setClosedPositions([]);
		}
	}, [isLoggedIn, subscriptionClosedPositions]);

	useEffect(() => {
		if (isLoggedIn && subscriptionPositions) {
			loadPositions();
		} else {
			cancelTimeoutPositions();
			setPositions([]);
		}
	}, [isLoggedIn, subscriptionPositions]);

	return {
		tradesHistory,
		openOrders,
		closedPositions,
		positions,
		positionsLoaded,

		subscribeTradesHistory,
		subscribeOpenOrders,
		subscribeClosedPositions,
		subscribePositions,

		unsubscribeTradesHistory,
		unsubscribeOpenOrders,
		unsubscribeClosedPositions,
		unsubscribePositions,
	};
};

const store = createStore(useTrades);

export const { Provider } = store;
export default store.ctx;
