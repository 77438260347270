import { useEffect, useState, useRef, useContext } from 'react';
import createStore from 'ctx-provider';
import { cloneDeep } from 'lodash';
import tradesCtx from 'context/trades';
import authenticationCtx from 'context/authentication';
import useAlert from 'hooks/useAlert';
import { getBalances } from 'api/trading-api';

const transformPhemexData = record => {
	return {
		symbol: record.currency,
		balance: parseFloat(record.accountBalanceRv),
		exchange: 'phemex',
	};
};

const useBalances = () => {
	const { positions, subscribePositions, positionsLoaded } = useContext(tradesCtx);
	const alert = useAlert();
	const { isLoggedIn } = useContext(authenticationCtx);
	const [balances, setBalances] = useState([]);

	const loadData = async () => {
		if (isLoggedIn) {
			try {
				const data = [];
				const payload = {
					exchange: 'phemex',
					network: 'contract',
					symbol: 'USDT',
				};

				let dataPhemex = await getBalances(payload);

				if (dataPhemex) {
					const dataPhemexContract = dataPhemex.map(transformPhemexData);
					const phemexBalance = dataPhemexContract[0];

					if (phemexBalance) {
						let balance = phemexBalance.balance;
						positions.forEach(position => (balance += position.profit));

						const balanceObject = {
							exchange: 'phemex',
							network: 'contract',
							balance,
						};

						Array.prototype.push.call(data, balanceObject);
					}

					await setBalances(cloneDeep(data));
				}
			} catch (err) {
				console.error(err);
				const message = err.response?.data?.error || err.message || 'Server error';
				// send an alert, but prevent alerting multiple times, by marking this message with "match":
				alert(message, { noDupes: true, match: `${message}#error`, severity: 'error' });
			}
		}
	};

	const refreshBalances = () => {
		if (isLoggedIn && positionsLoaded) {
			loadData();
		}
	};

	useEffect(() => {
		subscribePositions(); // we do not unsubscribe -> that will be handled by the main app
		refreshBalances();
	}, []);

	useEffect(() => {
		refreshBalances();
	}, [isLoggedIn, positions]);

	return {
		balances,
		refreshBalances,
	};
};

const store = createStore(useBalances);

export const { Provider } = store;
export default store.ctx;
