import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import authenticationCtx from 'context/authentication';
import balancesCtx from 'context/balances';
import tradesCtx from 'context/trades';
import darkmodeCtx from 'context/darkmode';
import floatToText from 'lib/float-to-text';
import { Link, navigate } from 'gatsby';
import useClickOutside from 'hooks/useClickOutside';
import { headerNavItems, userNavItems, ENVIRONMENT } from 'config/constants';
import useHasMounted from 'hooks/useHasMounted';
import Logo from 'assets/images/svg/logo.inline.svg';

const Header = props => {
	const { balances } = useContext(balancesCtx);
	const { positions, subscribePositions, unsubscribePositions } = useContext(tradesCtx);
	const { darkmode, toggleDarkmode } = useContext(darkmodeCtx);
	const hasMounted = useHasMounted();
	const [open, setOpen] = useState(false);
	const [openUserManagement, setOpenUserManagement] = useState(false);
	const [showUserToolTip, setShowUserToolTip] = useState(false);
	const { isLoggedIn, logout, scope } = useContext(authenticationCtx);
	const userMenuRef = useRef(null);
	const positionsCount = positions.length;

	let developmentLabel;

	useEffect(() => {
		subscribePositions();

		return () => {
			unsubscribePositions();
		};
	}, []);

	const navigateTo = url => {
		setOpen(false);
		setOpenUserManagement(false);
		navigate(url);
	};

	const handleLogout = () => {
		setOpenUserManagement(false);
		navigate('/');
		logout();
	};

	const handleOpenNavMenu = (e, isOpen) => {
		if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
			return;
		}
		setOpen(!!isOpen);
	};

	const handleOpenUserMenu = (e, isOpen) => {
		if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
			return;
		}
		setShowUserToolTip(false);
		setOpenUserManagement(!!isOpen);
	};

	useClickOutside([userMenuRef], handleOpenUserMenu);

	if (!hasMounted) {
		return null;
	}

	if (ENVIRONMENT === 'development') {
		developmentLabel = <div className="ml-auto">Development</div>;
	}

	const logo = (
		<Link to="/" className="my-auto">
			<Logo className="h-10" />
		</Link>
	);

	let menuItems;
	let menuItemsUser;
	let avatarOrLogin = (
		<button type="button" className="bg-white rounded-full px-6 py-1 text-black" onClick={() => navigateTo('/balance')}>
			Login
		</button>
	);

	if (isLoggedIn) {
		menuItems = headerNavItems.map(({ label, url }) => {
			const isTradingPage = label.toLowerCase() === 'trading';
			const showPositions = isTradingPage && positionsCount > 0;
			const path = window.location.pathname;
			const tradingPageSelected = path === '/trading/';

			let positionsCountNode;
			if (showPositions) {
				positionsCountNode = (
					<span
						className={clsx('py-1 px-2 rounded-full text-center ml-2', {
							'bg-yellow-300 text-black': tradingPageSelected,
							'bg-blue-500 text-white': !tradingPageSelected,
						})}
					>
						{positionsCount}
					</span>
				);
			}

			return (
				<Link
					to={url}
					className={clsx('bg-white rounded-full pl-6 py-1 text-black', {
						'pr-6': !showPositions,
						'pr-4': showPositions,
					})}
					activeClassName="!bg-blue-500 text-white"
					key={url}
				>
					{label}
					{positionsCountNode}
				</Link>
			);
		});

		menuItemsUser = userNavItems.map(({ label, url, scope }) => (
			<Link to={url} className="p-4 hover:bg-blue-500 hover:text-white" key={url}>
				{label}
			</Link>
		));

		const fullUserName = '';
		menuItemsUser.push(
			<button type="button" className="p-4 hover:bg-blue-500 hover:text-white" onClick={handleLogout} key="logout">
				Logout
			</button>,
		);
		avatarOrLogin = (
			<div className="relative" ref={userMenuRef}>
				<button
					type="button"
					className="border border-white p-1 rounded"
					onClick={e => handleOpenUserMenu(e, !openUserManagement)}
				>
					<img alt={fullUserName} src="/images/avatar.png" width="40" height="40" />
				</button>

				<div
					onClick={e => handleOpenUserMenu(e, false)}
					className={clsx(
						'flex flex-col mt-0 shadow-xl rounded absolute z-20 bg-white text-black right-0 whitespace-nowrap',
						{
							'invisible opacity-0': !openUserManagement,
							'visible opacity-100': openUserManagement,
						},
					)}
				>
					{menuItemsUser}
				</div>
			</div>
		);
	}

	menuItems = <nav className="flex space-x-4">{menuItems}</nav>;

	const hamburgerMenu = (
		<>
			<button onClick={event => handleOpenNavMenu(event, true)}>Menu</button>
			{menuItems}
		</>
	);

	const handleCloseUserToolTip = () => {
		setShowUserToolTip(false);
	};

	const handleOpenUserToolTip = () => {
		if (!openUserManagement) {
			setShowUserToolTip(true);
		}
	};

	let balancePhemex;
	if (isLoggedIn && balances[0]) {
		balancePhemex = floatToText(balances[0].balance, { append: 'balance: ', prepend: ' USD' });
	}

	return (
		<header className="container-fluid py-2 bg-black-42 border-b border-white">
			<div className="container flex items-center place-content-between">
				<div className="container flex justify-start items-center gap-6">
					{logo}
					{menuItems}
				</div>
				<div className="text-3xl text-yellow-300 font-bold">{developmentLabel}</div>
				<div className="container flex justify-end items-center gap-6">
					<div className="text-xl">
						<span className="text-blue-400 font-bold">{balancePhemex}</span>
					</div>
					{avatarOrLogin}
				</div>
			</div>
		</header>
	);
};

Header.defaultProps = {};

Header.propTypes = {
	uri: PropTypes.string.isRequired,
};

export default Header;
